export default{
    computed:{
        mealIds(){
            return [0,1,2,3,4,5,6]
        },
        activeMealIds() {
            return this.dietMeals.flat().map(x => x.type)
        }
    },
    data(){
        return {
            singleTypes: [0, 1, 3]
        }
    },
    methods:{
        getMealName(dietPlan, mealType, isPrint = false){
            if(dietPlan.dietPlanMealOptions.mealNames[mealType]) {
                return dietPlan.dietPlanMealOptions.mealNames[mealType];
            }

            // TODO: Revisit this in the future. It's not properly done.
            // It won't work properly with translated version.
            const translationStr = this.options?.translations?.dayMeals[mealType];

            if (isPrint && !!translationStr) return translationStr;

            return this.$t('diet_plan.meals.'+mealType);
        },
        getValidMealsOrder(dietPlan){

            let mealOptions = dietPlan.dietPlanMealOptions;

            return mealOptions.mealsOrder.filter(x => x < mealOptions.totalMeals);
        },

        isNewTypeFreeMeal(mealType){
            return this.singleTypes.includes(mealType)
        }
    }
}