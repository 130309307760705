<script>
import TopBar from '@components/top-bar'
import Footer from '@components/footer'
import OnlineStatus from "@components/global/OnlineStatus";
import SubscriptionExpiration from "@components/global/SubscriptionExpiration";
import {mapState} from "vuex";
import PageHeader from "@components/page-header";
import {localStorageKeys} from "@utils/util";
import MissingPhone from "@components/global/MissingPhone.vue";

export default {
  components: {MissingPhone, PageHeader, SubscriptionExpiration, TopBar, Footer, OnlineStatus },
  data() {
    return {
      isMenuOpened: false,
    }
  },
  computed:{
    ...mapState({
      user: state => state.auth.currentUser
    }),
  },
  created: () => {
    document.body.classList.remove('authentication-bg');
    document.body.classList.remove('authentication-bg-pattern');
  },


  methods: {
    toggleMenu() {
      this.isMenuOpened = !this.isMenuOpened
    },
  },
}
</script>

<template>
  <div>
    <header id="topnav">
      <TopBar :is-menu-opened="isMenuOpened"/>
    </header>

    <div class="wrapper">
      <div class="container-fluid">
        <PageHeader />
        <subscription-expiration v-if="user" :key="user.subscriptionDue" />
        <missing-phone v-if="user" />
        <slot />
      </div>

    </div>
    <Footer />
    <OnlineStatus/>
  </div>
</template>
