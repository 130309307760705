<script>

import auth from "./router/layouts/auth"
import chat from "./router/layouts/chat"
import cloud from "./router/layouts/cloud";
import mainLayout from "./router/layouts/main";
import {mapState} from "vuex";
export default {
  components:{auth, chat, cloud, mainLayout},
  page: {
  },
  computed:{
    ...mapState({
      token: state => state.auth.token
    }),
    getLayout(){
      return this.$route.meta.layout
    }
  },
  data(){
    return{
      chatInterval: null
    }
  },
  watch:{
    getLayout(val){
      if(val === 'auth') this.destroyChatInterval();
      else this.initializeChatInterval();
    },
    token(){
      if(!this.token){
        this.destroyChatInterval();
      }
    }
  },
  methods:{
    initializeChatInterval(){
      if(!this.token) return;
      if(this.chatInterval) return;
      if(this.isEducational || this.isOfflineVersion) return;

      this.chatInterval = setInterval(()=>{
        this.$store.dispatch('chat/getRecentChats');
      }, 10000);
    },

    destroyChatInterval(){
      if(this.chatInterval) clearInterval(this.chatInterval);
    }
  },
  mounted(){
    if(this.getLayout !== 'auth'){
      this.initializeChatInterval();
    }

  },

  destroyed(){
    this.destroyChatInterval();
  },
}

</script>

<template>
  <div id="app">

    <main-layout v-if="!getLayout">
      <router-view />
    </main-layout>
    <chat v-else-if="getLayout === 'chat'">
      <router-view />
    </chat>
    <auth v-else-if="getLayout === 'auth'">
      <router-view />
    </auth>
    <cloud v-else-if="getLayout === 'cloud'">
      <router-view />
    </cloud>

    <notifications group="athlisis-notifications"></notifications>
    <prolipsis-widget v-if="!isEducational && !isOfflineVersion
    && $store.getters['auth/loggedIn'] && !$route.path.includes('chat') && !$route.path.includes('calendar')" class="tablet-desktop-only" />
  </div>
</template>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
.apexcharts-svg .apexcharts-legend { position: fixed !important; }
  // Design variables and utilities from src/design.
  @import '@design';
  p, li, h1,h2,h3,h4,h5,h6{
    font-family: "Manrope",sans-serif!important;
  }
  .vue-notification-group{
    top:30px!important;
    right:10px!important;
  }
  .img-elements-not-found{
    margin-top:60px;
  }
  .headline-not-found{
    font-weight: 900!important;
  }
  .dg-btn{
    border-radius:0!important;
    font-size:13px!important;
    &.dg-btn--cancel{
      background-color: $color_primary!important;

    }
    &.dg-btn--ok{
      border-color:  $color_primary!important;
      .dg-btn-content{
        color:  $color_primary!important;
      }
    }
  }
  .dg-title{
    font-weight:900;
  }
  .dg-content-body {
    border-bottom: 1px solid #E1E6EA!important;
  }
  .dg-main-content{
    padding:50px!important;
  }
  input[type="text"]:disabled, input[type="number"]:disabled, .custom-select:disabled {
    background: #e9ecef!important;
    opacity: 0.7;
  }
  .questionnaire-tabs{
    .custom-control-label{
      cursor:pointer!important;
    }
  }
  .ti-new-tag-input-wrapper{
    font-family: "Manrope",sans-serif;
  }
  .ti-tag::before{
    font-family: "Themify";
  }
  [class^="ti-"], [class*=" ti-"]{
    font-family: $font-family-base!important;
  }
</style>
