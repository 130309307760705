<template>
  <b-alert :show="!user.phone && !isEducational && !isOfflineVersion && !isHidden" variant="warning" dismissible
           @dismissed="hideNotificationForOneDay"
  >
    {{ $t('pages.profile.profile.missingPhone') }}
    <b-button class="p-0 ml-2 -tw-mt-1" variant="link" @click="$router.push('/user')"><strong>
      {{ $t('pages.profile.profile.edit') }}
    </strong></b-button>
  </b-alert>

</template>
<script>
import {localStorageKeys} from "@utils/util";
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      user: state => state.auth.currentUser
    }),

    isHidden() {
      const hiddenTime = localStorage.getItem(localStorageKeys.hiddenPhoneNumber)
      return +hiddenTime > new Date().getTime()
    },
  },

  methods: {
    hideNotificationForOneDay() {
      localStorage.setItem(localStorageKeys.hiddenPhoneNumber, (new Date().getTime() + 86400000).toString())
    }
  }
}
</script>